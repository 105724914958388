import "./style.css";
function CardMaximizar({ imagem, titulo, conteudo }) {
  return (
    <div className="card-maximizar">
      <img src={imagem} alt="imagem card" style={{ width: "90px" }} />
      <span>{titulo}</span>
      <div style={{ height: "220px" }}>
        <p>{conteudo}</p>
      </div>
    </div>
  );
}

export default CardMaximizar;
