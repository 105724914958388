import "./style.css";
function CardSimplifique({ imagem, titulo, conteudo }) {
  return (
    <div className="card-simplifique">
      <img src={imagem} alt="imagem card" style={{ width: "50px" }} />
      <span>{titulo}</span>
      <p>{conteudo}</p>
    </div>
  );
}

export default CardSimplifique;
