import React, { useContext, useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import PlanosImagem from '../../components/Planos/PlanosImagem'
import Footer from "../../components/Footer/Footer";
import Botao from "../../components/Botao/Botao";
import './style.css'
import descritivo from '../../images/ERP/descritivo.svg'
import nfe from '../../images/ERP/nfe.svg'
import saas from '../../images/ERP/saas.svg'
import organize from '../../images/ERP/organize.svg'
import controle from '../../images/ERP/controle.svg'
import beneficios from '../../images/ERP/beneficios.svg'
import mlivre from '../../images/ERP/mlivre.svg'
import ifood from '../../images/ERP/ifood.svg'
import pix from '../../images/ERP/pix.svg'
import financeiro from '../../images/ERP/financeiro.svg'
import notafiscal from '../../images/ERP/notafiscal.svg'
import vendas from '../../images/ERP/vendas.svg'
import restaurante from '../../images/ERP/restaurante.svg'
import liberacao from '../../images/ERP/liberacao.svg'
import multiempresa from '../../images/ERP/multiempresa.svg'
import controleusuario from '../../images/ERP/controleusuario.svg'
import log from '../../images/ERP/log.svg'
import contas from '../../images/ERP/contas.svg'
import bancario from '../../images/ERP/bancario.svg'
import fluxocaixa from '../../images/ERP/fluxocaixa.svg'
import xml from '../../images/ERP/xml.svg'
import email from '../../images/ERP/email.svg'
import armazenamento from '../../images/ERP/armazenamento.svg'
import pdv from '../../images/ERP/pdv.svg'
import compra from '../../images/ERP/compra.svg'
import comissao from '../../images/ERP/comissao.svg'
import mesas from '../../images/ERP/mesas.svg'
import entregas from '../../images/ERP/entregas.svg'
import comida from '../../images/ERP/comida.svg'
import check from '../../images/ERP/check (3).svg'
import stone from '../../images/ERP/stone.png'
import { NavLink } from "react-router-dom";
import { EmailContext } from "../../context/EmailContext";

function ERP() {
    const { setPdf, setContabilidade, setOrigem } = useContext(EmailContext)
    // const handleContabilidade = () => {
    //     setContabilidade('Exactus ERP TESTE 15 dias')
    //     setOrigem('Assunto: Exactus ERP Teste 15 dias')
    // }
    useEffect(() => {
        setPdf('')
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner titulo={'Exactus ERP'} texto={'Um poderoso aliado para quem precisa de gestão financeira, vendas e emissão de todos os tipos de notas fiscais!'} classe={'banner-erp'} origem={'Assunto: Exactus ERP'} nomeProduto={'Exactus ERP'} />
            <PlanosImagem imagem={descritivo} titulo={'Descomplique sua Gestão empresarial com Exactus ERP, um sistema completo para empresas pequenas e médias!'} texto={''} />
            <section className="razoes erp">
                <h2>EXACTUS ERP</h2>
                <h3>Descomplicando sua Gestão empresarial</h3>
                <div className="container-erp">
                    <div className="content-erp">
                        <img src={nfe} alt="Ícone de NFe" style={{ width: '60px', height: '60px' }} />
                        <p>NFe ilimitadas, não se preocupe em gastar mais!</p>
                    </div>
                    <div className="content-erp">
                        <img src={saas} alt="Ícone de SAAS" style={{ width: '60px', height: '60px' }} />
                        <p>Sem instalação, acesse de qualquer lugar!</p>
                    </div>
                    <div className="content-erp">
                        <img src={organize} alt="Ícone de dinheiro" style={{ width: '60px', height: '60px' }} />
                        <p>Organize seu financeiro de forma simples!</p>
                    </div>
                    <div className="content-erp">
                        <img src={controle} alt="Ícone de controle" style={{ width: '60px', height: '60px' }} />
                        <p>Controle suas filiais com sistema integrado!</p>
                    </div>
                </div>
            </section>
            <section className="planos">
                <div className="elemento-centralizado">
                    <img src={beneficios} alt="Ícone de benefícios" style={{ width: '100px', height: '100px' }} />
                </div>
                <h2 style={{ textAlign: 'center', marginTop: '50px' }}>Integre seu negócio às principais soluções do mercado</h2>
                <div className="container-erp" style={{ marginBottom: '50px' }}>
                    <div className="card-erp">
                        <div className="elemento-centralizado">
                            <img src={mlivre} alt="Logo do Mercado Livre" style={{ width: '195px', height: '50px' }} />
                        </div>
                        <h3>Confirmação Automática</h3>
                        <p>Nosso sistema é integrado com o Mercado Livre, você faz seus anúncios diretamente pelo sistema.</p>
                        <p>Tenha o controle de suas vendas e estoque de maneira simples e integrada!</p>
                    </div>
                    <div className="card-erp">
                        <div className="elemento-centralizado">
                            <img src={ifood} alt="Logo do Ifood" style={{ width: '95px', height: '50px' }} />
                        </div>
                        <h3>Notificação ao Escritório</h3>
                        <p>Tenha o controle de seus pedidos integrado com o sistema da sua Empresa.</p>
                        <p>Nunca mais terá que digitar seus pedidos.</p>
                    </div>
                    <div className="card-erp">
                        <div className="elemento-centralizado">
                            <img src={pix} alt="Logo do PIX" style={{ width: '140px', height: '50px' }} />
                        </div>
                        <h3>Escrituração com um Clique</h3>
                        <p>Gostaria de receber suas vendas de forma ágil e ainda evitando  tarifas bancárias?</p>
                        <p>Com o Pix seu sistema recebe simplesmente o dinheiro direto para sua conta.</p>
                    </div>
                </div>
            </section>
            <section className="razoes">
                <h2>Conheça mais funcionalidades do EXACTUS ERP</h2>
                <div className="container-erp">
                    <div className="card-erp-branco">
                        <img src={financeiro} alt="Ícone de financeiro" style={{ width: '60px', height: '60px' }} />
                        <h3>Financeiro</h3>
                        <p>Gerencie suas finanças de maneira prática e rápida.</p>
                    </div>
                    <div className="card-erp-branco">
                        <img src={notafiscal} alt="Ícone de nota fiscal" style={{ width: '60px', height: '60px' }} />
                        <h3>Nota Fiscal</h3>
                        <p>Em um clique emita NFe/NFCe com assertividade.</p>
                    </div>
                    <div className="card-erp-branco">
                        <img src={vendas} alt="Ícone de vendas" style={{ width: '60px', height: '60px' }} />
                        <h3>Vendas e Compras</h3>
                        <p>Aumente seus canais de vendas.</p>
                    </div>
                </div>
                <div className="container-erp">
                    <div className="card-erp-branco">
                        <img src={restaurante} alt="Ícone de restaurante" style={{ width: '60px', height: '60px' }} />
                        <h3>Restaurante</h3>
                        <p>Digitalize os pedidos dos seus clientes e acompanhe todo o seu processo.</p>
                    </div>
                    <div className="card-erp-branco">
                        <img src={liberacao} alt="Ícone de cadeado" style={{ width: '60px', height: '60px' }} />
                        <h3>Liberação Dinâmica</h3>
                        <p>Evite que seus usuários façam operações indevidas dentro do sistema.</p>
                    </div>
                    <div className="card-erp-branco">
                        <img src={multiempresa} alt="Ícone de multiempresa" style={{ width: '60px', height: '60px' }} />
                        <h3>Multiempresa</h3>
                        <p>Gerencie todas as suas filiais em um único lugar.</p>
                    </div>
                </div>
                <div className="container-erp-menor">
                    <div className="card-erp-branco-menor">
                        <img src={controleusuario} alt="Ícone de usuário" style={{ width: '60px', height: '60px' }} />
                        <h3>Controle de Usuários</h3>
                        <p>Você controla quem pode ver as informações dentro do sistema.</p>
                    </div>
                    <div className="card-erp-branco-menor">
                        <img src={log} alt="Ícone de LOG" style={{ width: '60px', height: '60px' }} />
                        <h3>LOG</h3>
                        <p>Tenha auditoria automática na sua empresa.</p>
                    </div>
                </div>
            </section>
            <section className="planos">
                <h2 style={{ textAlign: 'center', margin: '0 0 50px 0' }}>Controle o financeiro da sua empresa, sem dificuldade</h2>
                <div className="container-erp">
                    <div className="content-erp">
                        <img src={contas} alt="Ícone de contas" style={{ width: '80px', height: '80px' }} />
                        <p style={{ margin: '20px 0 0 0' }}><b>Contas a pagar e receber</b></p>
                        <p style={{ margin: 0 }}>Saiba quais são as contas que você tem a receber de seus clientes e quais foram pagas.</p>
                    </div>
                    <div className="content-erp">
                        <img src={bancario} alt="Ícone de bancario" style={{ width: '80px', height: '80px' }} />
                        <p style={{ margin: '20px 0 0 0' }}><b>Lançamento Bancário</b></p>
                        <p style={{ margin: 0 }}>Controle as despesas e receitas movimentadas nas contas financeiras da sua empresa.</p>
                    </div>
                    <div className="content-erp">
                        <img src={fluxocaixa} alt="Ícone de caixa" style={{ width: '80px', height: '80px' }} />
                        <p style={{ margin: '20px 0 0 0' }}><b>Fluxo de caixa</b></p>
                        <p style={{ margin: 0 }}>Saiba quais foram as contas recebidas e pagas da sua empresa, organizado por período, data e usuário.</p>
                    </div>
                </div>
            </section>
            <section className="razoes">
                <h2>Controle o financeiro da sua empresa, sem dificuldade</h2>
                <div className="d-flex">
                    <div className='beneficios-itens' style={{ margin: 'auto' }}>
                        <ul>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Lançamento de crédito para clientes.</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Abertura e fechamento de caixa por turno.</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Remessa e retorno de boletos com baixa automática.</li>
                        </ul>
                    </div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/GFLq2wJI2Qs?si=oALCXgVpdo91NntQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </section>
            <section className="planos">
                <h2 style={{ textAlign: 'center', margin: '0 0 10px 0' }}>Facilidade na hora de emitir nota fiscal!</h2>
                <p style={{ margin: 0, textAlign: 'center' }}>Com o Beija-Flor ERP você emite todos os tipos de notas fiscais, ilimitadas.</p>
                <div className="container-erp">
                    <div className="content-erp">
                        <img src={xml} alt="Ícone de xml" style={{ width: '80px', height: '80px' }} />
                        <p style={{ margin: '20px 0 0 0' }}><b>Importação do XML</b></p>
                        <p style={{ margin: 0 }}>Faça a importação das notas enviadas pelo seu fornecedor.</p>
                    </div>
                    <div className="content-erp">
                        <img src={email} alt="Ícone de email" style={{ width: '80px', height: '80px' }} />
                        <p style={{ margin: '20px 0 0 0' }}><b>Envio de XML/DANFE por email</b></p>
                        <p style={{ margin: 0 }}>Envio do DANFE e arquivo XML por email para o seu cliente e contador.</p>
                    </div>
                    <div className="content-erp">
                        <img src={armazenamento} alt="Ícone de armazenamento" style={{ width: '80px', height: '80px' }} />
                        <p style={{ margin: '20px 0 0 0' }}><b>Armazenamento do arquivo XML</b></p>
                        <p style={{ margin: 0 }}>Todos os arquivos XMLs são armazenados no servidor de forma segura.</p>
                    </div>
                </div>
            </section>
            <section className="razoes">
                <h2>Nota Fiscal</h2>
                <div className="d-flex">
                    <div className='beneficios-itens' style={{ margin: 'auto' }}>
                        <ul>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Mesmo na nuvem, pode utilizar Certificado A3.</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Emissão, consulta, cancelamento e inutilização de NFe/NFCe/SAT.</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Cálculos automáticos de impostos.</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Integração com o módulo de vendas, evitando erros de digitação.</li>
                        </ul>
                    </div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/NNuabgagYPY?si=nI2qeT_BoghBoHQY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </section>
            <section className="planos">
                <h2 style={{ textAlign: 'center', margin: '0 0 10px 0' }}>Controle as vendas e compras da sua empresa, sem dificuldade</h2>
                <div className="container-erp">
                    <div className="content-erp">
                        <img src={pdv} alt="Ícone de Pedido de Venda" style={{ width: '80px', height: '80px' }} />
                        <p style={{ margin: '20px 0 0 0' }}><b>Pedido de Venda</b></p>
                        <p style={{ margin: 0 }}>Faça pedidos de venda para seus clientes e simulações por meio de orçamentos.</p>
                    </div>
                    <div className="content-erp">
                        <img src={compra} alt="Ícone de compra" style={{ width: '80px', height: '80px' }} />
                        <p style={{ margin: '20px 0 0 0' }}><b>Pedido de Compra</b></p>
                        <p style={{ margin: 0 }}>Faça um pedido de compra e envie para seu fornecedor direto do ERP.</p>
                    </div>
                    <div className="content-erp">
                        <img src={comissao} alt="Ícone de comissão" style={{ width: '80px', height: '80px' }} />
                        <p style={{ margin: '20px 0 0 0' }}><b>Comissão</b></p>
                        <p style={{ margin: 0 }}>Controle de comissão do vendedor por produto, grupo de produto e vendedor.</p>
                    </div>
                </div>
            </section>
            <section className="razoes">
                <h2>Controle suas vendas e compras</h2>
                <div className="d-flex">
                    <div className='beneficios-itens' style={{ margin: 'auto' }}>
                        <ul>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Emita diversos tipos de relatórios de acordo com sua necessidade.</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Status de pedido de compra.</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Acompanhe a movimentação do produto para efetuar as compras.</li>
                        </ul>
                    </div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/M0p7tOfmExI?si=Aj5uxoMLwkK_0FxT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </section>
            <section className="planos">
                <div className="stone">
                    <div>
                        <h2>Integração com a máquina de cartão Stone</h2>
                        <p>Além de deixar seu processo de venda ainda mais simples e rápido, essa integração permite sincronizar a venda sem que você precise ficar inserido manualmente o valor e quantidade de parcela na Stone, facilitando ainda mais seu dia a dia.</p>
                    </div>
                    <div>
                        <img src={stone} alt="Imagem da Stone" />
                    </div>
                </div>
            </section>
            <section className="razoes">
                <h2>Transforme seu negócio, sistema para bares, lanchonetes e restaurantes</h2>
                <div className="container-erp">
                    <div className="content-erp">
                        <img src={mesas} alt="Ícone de mesas" style={{ width: '80px', height: '80px' }} />
                        <p style={{ margin: '20px 0 0 0' }}><b>Controle de mesas</b></p>
                        <p style={{ margin: 0 }}>Realize pedidos por tablets e celulares.</p>
                    </div>
                    <div className="content-erp">
                        <img src={entregas} alt="Ícone de entregas" style={{ width: '80px', height: '80px' }} />
                        <p style={{ margin: '20px 0 0 0' }}><b>Controle de entregas</b></p>
                        <p style={{ margin: 0 }}>Exclusivo controle de pedidos para entrega, mais praticidade ao seu delivery.</p>
                    </div>
                    <div className="content-erp">
                        <img src={comida} alt="Ícone de comida" style={{ width: '80px', height: '80px' }} />
                        <p style={{ margin: '20px 0 0 0' }}><b>IFood</b></p>
                        <p style={{ margin: 0 }}>Receba seus pedidos no Ifood, pelo nosso sistema.</p>
                    </div>
                </div>
            </section>
            <section className="planos">
                <h2 style={{ textAlign: 'center' }}>Gerencie seu restaurante</h2>
                <div className="d-flex">
                    <div className='beneficios-itens' style={{ margin: 'auto' }}>
                        <ul>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Frente de caixa local, controle mesas e pedidos mesmo sem internet.</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Modo de servir: acrescente produtos adicionais no pedido.</li>
                            <li><img src={check} alt='Ícone de check' style={{ width: '15px', height: '15px' }} />Ganhe tempo e agilidade no processo interno da empresa.</li>
                        </ul>
                    </div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/lZ8KkXcBnSs?si=QYGimKmEljUKwO1J" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </section>
            <section className="razoes">
                <h2>Conheça mais funcionalidades do Beija-Flor ERP</h2>
                <div className="container-erp">
                    <div className="card-erp-saibamais">
                        <div className="elemento-centralizado">
                            <img src={liberacao} alt="Ícone de liberação" style={{ width: '60px', height: '60px' }} />
                        </div>
                        <h3>Liberação Dinâmica</h3>
                        <p>Evite que seus usuários façam operações indevidas dentro do sistema.</p>
                    </div>
                    <div className="card-erp-saibamais">
                        <div className="elemento-centralizado">
                            <img src={multiempresa} alt="Ícone de multiempresa" style={{ width: '60px', height: '60px' }} />
                        </div>
                        <h3>Multiempresa</h3>
                        <p>Gerencie todas as suas filiais em um único lugar.</p>
                    </div>
                    <div className="card-erp-saibamais">
                        <div className="elemento-centralizado">
                            <img src={controleusuario} alt="Ícone de controle" style={{ width: '60px', height: '60px' }} />
                        </div>
                        <h3>Controle de Usuários</h3>
                        <p>Você controla quem pode ver as informações dentro do sistema.</p>
                    </div>
                    <div className="card-erp-saibamais">
                        <div className="elemento-centralizado">
                            <img src={log} alt="Ícone de LOG" style={{ width: '60px', height: '60px' }} />
                        </div>
                        <h3>Log</h3>
                        <p>Tenha auditoria automática na sua empresa.</p>
                    </div>
                </div>
                {/* <div className="elemento-centralizado" style={{ marginTop: '50px' }}>
                    <NavLink to="https://www.beijaflorerp.com.br/nova-conta" target="_blank">
                        <Botao texto={'Experimente Grátis por 15 dias!'} />
                    </NavLink>
                </div> */}
            </section>
            <Footer />
        </>
    )
}

export default ERP